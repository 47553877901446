<template>
<v-row>
    <v-col cols="12" lg="9">
        <v-row>
            <v-col cols="12" md="6" v-for="(item, index) in item_points" :key="index">
                <base-card>
                    <v-list-item three-line>
                        <v-list-item-content>
                            <!-- <v-list-item-subtitle class="body-2">Total</v-list-item-subtitle> -->
                            <div class="body-2 mb-0 text--disabled">
                                {{ item.title }}
                            </div>
                            <v-list-item-title class="headline mb-1 font-weight-bold">{{item.total}}</v-list-item-title>
                            <v-list-item-subtitle>
                                <div class="d-flex flex-wrap">
                                    <v-sheet height="20" width="20" class="info lighten-5  d-inline-flex align-center justify-center rounded-circle mr-1">
                                        <v-icon class="info--text caption">mdi-arrow-top-right</v-icon>
                                    </v-sheet>
                                    <span class="font-weight-bold text-info">14% Inc</span>
                                </div>
                            </v-list-item-subtitle>
                        </v-list-item-content>

                        <apexchart width="100" height="150" type="radialBar" :options="jmPieChartOne.chartOptions" :series="jmPieChartOne.series" />
                    </v-list-item>
                </base-card>
            </v-col>
     
            
         
            <v-col cols="12">
                <base-card>
                <v-carousel
                cycle
                hide-delimiter-background
               show-arrows-on-hover
                >
    <v-carousel-item
      v-for="(item,i) in itemss"
      :key="i"
      :src="item.src"
     
     
    ></v-carousel-item>
  </v-carousel>
                </base-card>
            </v-col>
        
        </v-row>
    </v-col>
    <v-col cols="12" lg="3" class="mt-3">
        <base-card>
            <v-row>
                <v-col cols="12" class="text-center">
                    <v-avatar size="90">
                        <v-img src="@/assets/images/faces/15.jpg" />
                    </v-avatar>
                    <div class="card-title ma-0 text-h5">ONG ESPOIRE DE COTONOU</div>
                    <div class="d-flex align-center justify-center">
                        <v-icon class="text-primary body-1 mr-1">mdi-map-marker</v-icon>
                        <span class="text-primary text-12">New York, US cotonou parakou </span>
                    </div>
                </v-col>
               
                <v-col cols="12">
                    <v-card-text>
                        <div class="mb-10">
                            <v-card-subtitle class="pa-0">
                                <h6 class="caption tracking-widest">
                                    Autres informations
                                </h6>
                            </v-card-subtitle>
                            <v-list-item class="pl-0" v-for="item in items" :key="item.title">
                                <v-list-item-avatar>
                                    <v-icon :class="[item.iconClass]" v-text="item.icon" />
                                </v-list-item-avatar>

                                <v-list-item-content>
                                    <v-list-item-title class="body-2 font-weight-medium" v-text="item.title" />
                                    <v-list-item-subtitle v-text="item.subtitle" />
                                </v-list-item-content>

                                <v-list-item-action>
                                    <v-btn icon>
                                        <v-icon class="dark--text text--lighten-3">mdi-dots-horizontal</v-icon>
                                    </v-btn>
                                </v-list-item-action>
                            </v-list-item>
                        </div>
                    </v-card-text>
                </v-col>
            </v-row>
        </base-card>
      
    </v-col>
</v-row>
</template>

<script>
import avatarGroupCard from '@/components/card/AvatarGroupCard'
import api from '../../../../serviceApi/apiService'
import {
    jmPieChartOne,
    jmPieChartTwo,
    jmBarChart,
    jmLineChart,
    jmDonutChart
} from '@/data/jobManagement'

export default {
    name: 'Dashboard',
    metaInfo: {
        // title will be injected into parent titleTemplate
        title: 'Dashboard'
    },
    components: {
        avatarGroupCard
    },
    data() {
        return {
            jmPieChartOne,
            jmPieChartTwo,
            jmBarChart,
            jmLineChart,
            jmDonutChart,
            valueDeterminate: 50,
            valueDeterminate2: 20,
            valueDeterminate3: 60,
            valueDeterminate4: 34,
            valueDeterminate5: 35,
            valueDeterminate6: 15,
            desserts: [{
                    job: 'Senior Fullstack Engineer',
                    posted: '01/10/2020',
                    applicants: require('@/assets/images/avatars/001-man.svg')
                },
                {
                    job: 'Freelance Java Developer',
                    posted: '01/10/2020',
                    applicants: require('@/assets/images/avatars/001-man.svg')
                },
                {
                    job: 'Python Developer',
                    posted: '01/10/2020',
                    applicants: require('@/assets/images/avatars/001-man.svg')
                },
                {
                    job: 'Product Designer',
                    posted: '01/10/2020',
                    applicants: require('@/assets/images/avatars/001-man.svg')
                }
            ],
            items: [{
                    icon: 'mdi-view-column',
                    iconClass: 'dark--text text--lighten-4 grey lighten-4',
                    title: 'IFU',
                    subtitle: '0012441588'
                },
                {
                    icon: 'mdi-google-maps',
                    iconClass: 'dark--text text--lighten-4 grey lighten-4',
                    title: 'Adresse',
                    subtitle: 'KINDONOU'
                },
                {
                    icon: 'mdi-phone',
                    iconClass: 'dark--text text--lighten-4 grey lighten-4',
                    title: 'Telephone',
                    subtitle: '00000000'
                },
                {
                    icon: 'mdi-at',
                    iconClass: 'dark--text text--lighten-4 grey lighten-4',
                    title: 'E-mail',
                    subtitle: 'test@gmail.com'
                }
            ],
            itemss: [
          {
            src: 'https://undp.shorthandstories.com/gef-sgp-changer-des-vies/assets/auKhIM9av0/gef-SGP-UNDP-LogoLineUp2-1352x443.png',
          },
          {
            src: 'https://matinlibre.com/wp-content/uploads/2019/12/PNUD.png',
          },
         
        ],
        item_points:[
            {
                icon: 'mdi-account-group',
                iconClass: 'dark--text text--lighten-4 grey lighten-4',
                title: "Nombre Animateur",
                total:'0000',
            },
            {
                icon: 'mdi-comment-account-outline',
                iconClass: 'dark--text text--lighten-4 grey lighten-4',
                title: "Nombre de Projets",
                total: '0000',
            },
           
           ]
        }
    },
    methods: {
        read_point: async function(){
           const data=await api.readAll('statistique/point-accueil-ong');
              console.log(data);
             
                this.item_points[0].total=data.animateur;
                this.item_points[1].total=data.projet;
              

        }
    },
    mounted() {
        this.read_point();
    }
}
</script>
